<template>
  <div class="" id="image_upload">
    <button type="button" @click="toggleShow"><i class="fa fa-camera"></i> Profile Picture</button>
    <my-upload field="upload"
               @crop-success="cropSuccess"
               @crop-upload-success="cropUploadSuccess"
               @crop-upload-fail="cropUploadFail"
               v-model="show"
               :width="150"
               :height="150"
               :url="url"
               method="POST"
               :params="params"
               :headers="headers"
               lang-type="en"
               :lang-ext="langExt"
               :no-rotate="false"
               img-format="png">
    </my-upload>
    <img :src="imgDataUrl" style="" class="img-fluid img-thumbnail rounded-circle">
  </div>
</template>

<script>
  import myUpload from 'vue-image-crop-upload/upload-2.vue';
  // import myUpload from 'vue-image-crop-upload';

  export default {
    name:'ImageUpload',
    data(){
      return{
        show: false,
        participantId:null,
        params: {
          token: '123456798',
          name: 'avatar',
          id: this.parentData.participantId
        },
        headers: {
          smail: '*_~'
        },
        url:this.parentData.avatarUrl,
        // url:this.$http.options.root+'avatar/upload',
        langExt:{
          hint: 'Click or drag the file here to upload',
          loading: 'Uploading…',
          noSupported: 'Browser is not supported, please use IE10+ or other browsers',
          success: 'Upload success',
          fail: 'Upload failed',
          preview: 'Preview',
          btn: {
            off: 'Cancel',
            close: 'Close',
            back: 'Back',
            save: 'Save'
          },
          error: {
            onlyImg: 'Image only',
            outOfSize: 'Image exceeds size limit: ',
            lowestPx: 'Image\'s size is too low. Expected at least: '
          }
        },
        imgDataUrl: null
      }
    },
    components:{
      'my-upload': myUpload
    },
    props:{
      parentData:Object
    },
    beforeCreate(){
      if (JSON.parse(localStorage.getItem('participant')) !== null){
        this.participantId = JSON.parse(localStorage.getItem('participant')).id;
      }
    },
    created(){
      if (JSON.parse(localStorage.getItem('participant')) !== null){
        this.imgDataUrl = localStorage.getItem('avatar');
      }else if (localStorage.getItem('avatar')){
        this.imgDataUrl = localStorage.getItem('avatar');
      }else {
        this.imgDataUrl = require(`@/assets/profile_picture/${'avatar.png'}`) // the datebase64 url of created image
      }
    },
    methods:{
      toggleShow() {
        this.show = !this.show;
      },
      /**
       * crop success
       *
       * [param] imgDataUrl
       * [param] field
       */
      // cropSuccess(imgDataUrl, field){
      cropSuccess(imgDataUrl, field){
        console.log(imgDataUrl+'-------- crop success --------'+field);
        this.imgDataUrl = imgDataUrl;
      },
      /**
       * upload success
       *
       * [param] jsonData  server api return data, already json encode
       * [param] field
       */
      cropUploadSuccess(jsonData, field){
        console.log('-------- upload success --------');
        console.log(jsonData);
        console.log('field: ' + field);
        localStorage.setItem('avatar',jsonData);
        this.imgDataUrl = jsonData;
      },
      /**
       * upload fail
       *
       * [param] status    server api return error status, like 500
       * [param] field
       */
      cropUploadFail(status, field){
        console.log('-------- upload fail --------');
        console.log(status);
        console.log('field: ' + field);
      }
    }
  }
</script>

<style scoped>
  #image_upload{
    position: relative;
  }
  #image_upload >button{
    position: absolute;
    width: 100%;
    padding: 10px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background-color: rgba(0,0,0,0.3);
    font-weight: bold;
    color: #000000;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    /*border: 5px solid #ffffff;*/
    /*border-top: 0px solid #ffffff;*/
    /*box-sizing: border-box;*/
  }

  #image_upload>button:hover{
    color: #dddddd;
    background-color: #000000;
    transition: all 0.3s;
  }
</style>